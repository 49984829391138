import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSubmitted(true);
        setFormData({ name: "", email: "", website: "" });
      } else {
        throw new Error("Failed to submit");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(
        "There was an error submitting your information. Please try again."
      );
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className={`fade-in ${visible ? "visible" : ""}`}>Suay</h1>
        <p className={`hero-subtitle ${visible ? "visible" : ""}`}>
          AI-Powered Solutions for Aesthetic Practices
        </p>
      </header>

      <main>
        <section id="hero" className={`fade-in ${visible ? "visible" : ""}`}>
          <div className="hero-content">
            <h2 className="animated-text">Empower Your Aesthetic Practice</h2>
            <p>Launch faster. Boost profits. Reclaim your time.</p>
            <a href="#waitlist" className="cta-button">
              Join Waitlist
            </a>
          </div>
          <div className="hero-image">
            <img src="/image_face.jpg" alt="Aesthetic Practice" />
          </div>
        </section>

        <section id="video" className={`fade-in ${visible ? "visible" : ""}`}>
          <h2 className="animated-text">See us in Action</h2>
          <div className="video-container">
            <video controls src="/elym-demo.mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <section
          id="who-we-are"
          className={`slide-in ${visible ? "visible" : ""}`}
        >
          <h2 className="animated-text">Who We Are</h2>
          <div className="who-we-are-content">
            <div className="who-we-are-text">
              <p>
                At Suay, we're driven by a passion to revolutionize the
                aesthetic practice industry. Our journey began with a simple
                observation: clinicians are overworked, underpaid, and burdened
                by administrative tasks.
              </p>
              <p>
                We understand the challenges faced by physicians who seek
                alternative income sources without compromising their medical
                expertise. That's why we've created an AI-powered solution that
                empowers clinicians to launch, operate, and scale their own
                aesthetic practices with ease.
              </p>
              <p>Our values are rooted in:</p>
              <ul>
                <li>Empowering clinicians to maintain autonomy and control</li>
                <li>Streamlining processes to reduce administrative burdens</li>
                <li>Leveraging AI to boost efficiency and profitability</li>
                <li>Providing comprehensive support for practice growth</li>
              </ul>
            </div>
            <div className="who-we-are-image">
              <img src="/office.jpg" alt="Suay Team" />
            </div>
          </div>
        </section>

        <section
          id="features"
          className={`slide-in ${visible ? "visible" : ""}`}
        >
          <h2 className="animated-text">Key Features</h2>
          <div className="feature-grid">
            <div className="feature hover-lift">
              <h3 className="animated-text">Rapid Launch</h3>
              <p>Go from concept to operational in just 15-30 days.</p>
            </div>
            <div className="feature hover-lift">
              <h3 className="animated-text">AI-Powered Management</h3>
              <p>Comprehensive platform for all your practice needs.</p>
            </div>
            <div className="feature hover-lift">
              <h3 className="animated-text">Growth Acceleration</h3>
              <p>AI-driven marketing and client acquisition tools.</p>
            </div>
          </div>
        </section>

        {/*<section
            id="founders"
            className={`fade-in ${visible ? "visible" : ""}`}
          >
            <h2 className="animated-text">Meet Our Founders</h2>
            <div className="founders-container">
              <div className="founder">
                <div className="founder-image-container elena">
                  <img
                    src="/elena.jpeg"
                    alt="Elena Iannucci"
                    className="founder-image"
                  />
                </div>
                <h3>Elena Iannucci</h3>
                <p>Co-Founder & CEO</p>
              </div>
              <div className="founder">
                <div className="founder-image-container alessia">
                  <img
                    src="/alessia.jpeg"
                    alt="Alessia Paccagnella"
                    className="founder-image"
                  />
                </div>
                <h3>Alessia Paccagnella</h3>
                <p>Co-Founder & CTO</p>
              </div>
            </div>
          </section>*/}

        <section id="cta" className={`slide-in ${visible ? "visible" : ""}`}>
          <h2 className="animated-text">Ready to Transform Your Practice?</h2>
          <p>
            Join our waitlist and be among the first to revolutionize your
            aesthetic practice.
          </p>
        </section>

        <section
          id="waitlist"
          className={`fade-in ${visible ? "visible" : ""}`}
        >
          <h2 className="animated-text">Join the Waitlist</h2>
          {submitted ? (
            <p className="thank-you">
              Thank you for joining our waitlist! We'll be in touch soon.
            </p>
          ) : (
            <form onSubmit={handleSubmit} className="animated-form">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
                className="hover-input"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                required
                className="hover-input"
              />
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
                placeholder="Your Practice Website (if applicable)"
                className="hover-input"
              />
              <button type="submit" className="cta-button hover-button">
                Join Waitlist
              </button>
            </form>
          )}
        </section>
      </main>

      <footer>
        <p>&copy; Suay</p>
        <p>
          Contact us: <a href="mailto:joinsuay@gmail.com">joinsuay@gmail.com</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
